import { Dispatch, SetStateAction, useState } from "react";
import { SurveyData } from "../../../survey.types";
import PlanEditor from "../../PlanEditor";

export default function WizardPlanForm({
  survey,
  setSurvey,
  showNext,
  onSubmit,
}: {
  survey: SurveyData;
  setSurvey: Dispatch<SetStateAction<SurveyData>>;
  showNext?: boolean;
  onSubmit: () => void;
}) {
  return (
    <div className="pb-4 flex-1 pr-6">
      <div className="text-sm text-gray-600 mb-4 italic">
        Manually edit plan steps or update your survey description and
        regenerate results.
      </div>
      <div className="">
        <div className="w-full mb-4">
          <PlanEditor setSurvey={setSurvey} survey={survey} />
        </div>

        {showNext ? (
          <div>
            <div className="flex flex-row justify-end">
              <button
                onClick={onSubmit}
                className="inline-flex items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
              >
                Next
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
